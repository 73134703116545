import React from 'react';

export const Clock = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.981 1C9.493 1 1 9.512 1 20C1 30.488 9.493 39 19.981 39C30.488 39 39 30.488 39 20C39 9.512 30.488 1 19.981 1ZM20 35.2C11.602 35.2 4.8 28.398 4.8 20C4.8 11.602 11.602 4.8 20 4.8C28.398 4.8 35.2 11.602 35.2 20C35.2 28.398 28.398 35.2 20 35.2Z"
      fill="currentColor"
    />
    <path
      d="M21 10H18V22.459L28.5 29L30 26.4459L21 20.9016V10Z"
      fill="currentColor"
    />
  </svg>
);
