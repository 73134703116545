/**
 * @deprecated Use URLSearchParams
 */
const params = (query: string): Record<string, string | number> => {
  return query
    .slice(1)
    .split('&')
    .filter(Boolean)
    .reduce((accumulator, value) => {
      const pair = value.split('=');

      accumulator[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);

      return accumulator;
    }, {});
};

/**
 * @deprecated Use URL and URL.searchParams.set
 */
const url = (
  base: string,
  filters: Record<string, string | number>,
  sort: (number | string)[] = [],
): string => {
  if (Object.keys(filters).length === 0 && sort.length === 0) {
    return base;
  }

  let url = `${base}?`;
  let first = true;

  Object.keys(filters).forEach((key) => {
    if (!first) {
      url += '&';
    }
    url += `filter[${key}]=${filters[key]}`;
    first = false;
  });

  if (sort.length > 0) {
    if (!first) {
      url += '&';
    }
    url += `sort=${sort.join(',')}`;
    first = false;
  }

  return url;
};

/**
 * Should ideally not be used, but our tests are checking that the url
 * don't contain = when there is no value for search params.
 * This function removes the = if there is no values.
 */
export const URLtoCleanString = (url: URL | URLSearchParams): string => {
  let result = url.toString();
  result = result.replaceAll('=&', '&');
  if (result[result.length - 1] === '=') {
    result = result.substring(0, result.length - 1);
  }
  return result;
};

/**
 * @deprecated Favor using the URL and URLSearchParams native functions
 */
export default { url, params };
