import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import mode from '../../shared/helpers/Mode';
import FastRouteModal from '../components/FastRouteModal';
import TrackPageView from '../components/TrackPageView';
import { MEETING_METHODS, PAGES, SHORTCUTS } from '../constants';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Api from '../helpers/Api';
import Item from '../helpers/Item';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import DesktopService from './desktop/Service';
import MobileService from './mobile/Service';

const Service = ({ next, previous, stepsCount, currentStep, previousStep }) => {
  const intl = useIntl();
  const history = useHistory();
  const [locale] = useContext(LocaleContext);
  const layout = useContext(ViewModeContext);
  const [selections, setSelections] = useContext(SelectionContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const useNewKiosk = useMemo(
    () => searchParams.has('use_new_kiosk'),
    [searchParams],
  );
  const isWalkIn = useMemo(() => searchParams.has('walk_in'), [searchParams]);

  const [expanded, setExpanded] = useState('0');

  const [{ loading, services, categories, firstCategory }, setInformation] =
    useReducer((state, newState) => newState, {
      categories: [],
      firstCategory: null,
      loading: true,
      services: [],
    });

  const [category, setCategory] = useReducer(
    (state, newState) => newState,
    firstCategory,
  );
  const [selectedService, setSelectedService] = useState(null);

  const selectCategory = (id) => {
    const serviceCategory = categories.find(
      (category) => category.id === `${id}`,
    );
    setCategory(serviceCategory);
    setSelections({ serviceCategory });
  };

  const goToService = () => {
    setSelections(selectedService);
    next(selectedService);
  };

  const selectService = (service, serviceCategory) => {
    const selections = { service };

    if (serviceCategory) {
      setSelections({ serviceCategory });
    }

    if ((service.fastRouteMessage || service.fastRouteURL) && !mode.isKiosk()) {
      setSelectedService(selections);
    } else {
      setSelections(selections);
      next(selections);
    }
  };

  const toggleDescription = (id) => setExpanded(expanded === id ? '0' : id);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'Steps.service' });
  }, [intl]);

  useEffect(() => {
    const processServicesData = (services) => {
      const counts = {};
      const items = services.map((service) =>
        Resources.formatV3Service(service),
      );
      const categories = services.reduce((accumulator, service) => {
        service.categories.forEach((category) => {
          if (!counts[category.id]) {
            counts[category.id] = 0;
          }

          counts[category.id] += 1;
          accumulator[category.id] = category;
        });

        return accumulator;
      }, {});
      const separators = Object.values(categories)
        .map((separator) => {
          separator.serviceCount = counts[separator.id];

          return separator;
        })
        .sort((a, b) => {
          if (a.sort_order < b.sort_order) {
            return -1;
          }

          if (a.sort_order > b.sort_order) {
            return 1;
          }

          return a.name > b.name ? 1 : -1;
        });

      setInformation({
        categories: separators,
        firstCategory: Item.first(separators),
        loading: false,
        services: items,
      });

      if (category) {
        setCategory(separators.find((sep) => sep.id === category?.id));
      }
    };

    if (
      mode.isKiosk() &&
      searchParams.has('walk_in') &&
      searchParams.has('use_new_kiosk') &&
      selections.bookingWalkIn &&
      shouldUseKioskEnhancements
    ) {
      return Api.locale(locale)
        .services()
        .all({
          location: selections.location ? selections.location.id : null,
          lobbyAvailability: true,
          method: MEETING_METHODS.AT_LOCATION,
          settings: selections.settings,
        })
        .then((services) => processServicesData(services));
    }

    Api.locale(locale)
      .services()
      .all({
        category: Shortcuts.exists(SHORTCUTS.CATEGORY)
          ? Shortcuts.identify(SHORTCUTS.CATEGORY)
          : null,
        method: Shortcuts.exists(SHORTCUTS.MEETING_METHOD)
          ? Number(Shortcuts.get(SHORTCUTS.MEETING_METHOD))
          : null,
        location: selections.location
          ? selections.location.id
          : Shortcuts.exists(SHORTCUTS.LOCATION)
          ? Shortcuts.identify(SHORTCUTS.LOCATION)
          : null,
        locationCategory: selections.locationCategory?.id,
        region: Shortcuts.exists(SHORTCUTS.REGION)
          ? Shortcuts.get(SHORTCUTS.REGION)
          : null,
        service:
          Shortcuts.exists(SHORTCUTS.SERVICE) &&
          !(shouldUseKioskEnhancements && useNewKiosk && isWalkIn)
            ? Shortcuts.identify(SHORTCUTS.SERVICE)
            : null,
        settings: selections.settings,
        user: selections.user
          ? selections.user.id
          : Shortcuts.exists(SHORTCUTS.USER)
          ? Shortcuts.identify(SHORTCUTS.USER)
          : null,
        userCategory: selections.userCategory?.id,
      })
      .then((services) => {
        processServicesData(services);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, selections, setInformation]);

  const props = {
    categories,
    category,
    currentStep,
    expanded,
    firstCategory,
    loading,
    previous,
    previousStep,
    selectCategory,
    selectService,
    services,
    stepsCount,
    toggleDescription,
  };

  return (
    <>
      {!mode.isKiosk() ? (
        <FastRouteModal
          data={{
            message: selectedService?.service?.fastRouteMessage,
            url: selectedService?.service?.fastRouteURL,
          }}
          handleDismiss={() => setSelectedService(null)}
          handleServiceSelect={goToService}
          open={!!selectedService}
        />
      ) : null}
      <TrackPageView identifier={PAGES.SERVICE} />
      {layout === MOBILE ? (
        <MobileService {...props} />
      ) : (
        <DesktopService {...props} mode={layout} />
      )}
    </>
  );
};

Service.propTypes = {
  currentStep: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  stepsCount: PropTypes.number.isRequired,
};

Service.defaultProps = {
  previousStep: null,
};

export default Service;
