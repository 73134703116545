import type { FC } from 'react';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { NextArrow } from './icons/NextArrow';
import Typography from './Typography';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    alignItems: 'center',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: theme.fontWeights.medium,
    margin: 0,
    marginRight: '1.25rem',
    padding: 0,
    '& svg': {
      fill: theme.palette.neutral[400],
      marginBottom: '0.125rem',
      marginLeft: '0.75rem',
    },
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
      borderBottomColor: theme.palette.secondary[500],
      color: theme.palette.secondary[500],
      fontWeight: theme.fontWeights.boldWeight,
      '& $caption': {
        color: theme.palette.black,
      },
      '& svg': {
        fill: theme.palette.black,
      },
    },
  },
  caption: {
    textTransform: 'uppercase',
  },
}));

interface NextButtonProps {
  next: () => void;
  text: string | JSX.Element;
  title?: string;
}

export const NextButton: FC<NextButtonProps> = ({ next, text, title = '' }) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <button
      className={classes.root}
      data-testid="next-button"
      onClick={next}
      title={title}
      type="button"
    >
      <Typography classes={{ root: classes.caption }} variant="caption1">
        {text}
      </Typography>
      <NextArrow altText={title} ariaHidden />
    </button>
  );
};
