import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import { LocaleContext } from './LocaleContext';

const INITIAL_SETTINGS = {
  availableLanguages: [],
  bookingShortcutCustomExpiredMessage: null,
  bookingShortcutCustomExpiredMessageContent: null,
  clientFields: {},
  clientViewWithoutBookingShortcut: null,
  callback_contact_options: {},
  callback_service_help: '',
  callsToAction: [],
  customContact: null,
  customContactAdditionalMessage: null,
  customContactHeading: null,
  defaultLanguage: '',
  disabledDescription: null,
  disabledTitle: null,
  explicitComplianceAgreement: null,
  firstStep: null,
  hideLocationHours: null,
  homepage: null,
  includeLocationStep: null,
  joinLineFromClientView: null,
  loading: true,
  locationSearchRadius: null,
  notificationsGeneralEvents: null,
  preBookingStatement: null,
  privacyPolicyUrl: null,
  receiveSmsNotifications: null,
  reorderSpecificStaffBeforeLanguages: null,
  serviceStepLayout: null,
  showLocationPhoneCallToAction: null,
  showStaffLanguages: null,
  showWaitTime: null,
  staffAvailableLanguages: [],
  timeInAdvance: null,
  timeToCancel: null,
  timeToReschedule: null,
  termsOfServiceUrl: null,
  waitListEnabled: null,
  showStaffPhotos: null,
  staffSelection: null,
  walkin_questions: null,
  useNearestLocation: false,
  kioskSelectPreferredLang: false,
  kioskSelectPreferredStaff: false,
};

const SettingsContext = createContext(INITIAL_SETTINGS);

const SettingsProvider = ({ children }) => {
  const Api = useMemo(() => Open.api(), []);

  const [locale] = useContext(LocaleContext);
  const [settings, setSettings] = useReducer(
    (state, newState) => newState,
    INITIAL_SETTINGS,
  );

  useEffect(() => {
    Api.locale(locale)
      .settings()
      .get()
      .then(
        ({
          data: {
            data: { attributes },
          },
        }) => {
          setSettings({
            ...Item.keysToCamel(attributes),
            loading: false,
          });
        },
      );
  }, [Api, locale]);

  if (settings.loading) {
    return null;
  }

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SettingsContext, SettingsProvider };
