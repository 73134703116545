import { useContext } from 'react';
import { useIntl } from 'react-intl';
import Strings from '../../shared/helpers/Strings';
import { LANGUAGES } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';

export const useTranslatedLocaleString = () => {
  const intl = useIntl();
  const [locale] = useContext(LocaleContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const supportedLanguagesTranslations =
    (window as any)?.state?.spoken_language_list || {};

  return {
    getTranslatedLocaleString: (abbr: string) => {
      return spokenLanguages && supportedLanguagesTranslations?.[locale]?.[abbr]
        ? Strings.handleCapitalization(
            supportedLanguagesTranslations[locale][abbr],
            locale,
          )
        : intl.formatMessage({
            id: `Languages.${LANGUAGES?.[abbr]?.toLowerCase()}`,
          });
    },
  };
};
