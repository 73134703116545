import { grey, blue, green, red, amber } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import parseColor from 'parse-color';

export const MIN_TEXT_CONTRAST_RATIO = 4.5;
export const MIN_LARGE_TEXT_CONTRAST_RATIO = 3;
export const MIN_COMPONENT_CONTRAST_RATIO = 3;
export const BLACK = '#212121';
export const WHITE = '#FFFFFF';
const DEFAULT_MIN_CONTRAST_BLUE = '#156DFF';

/**
 * Used to calculate the luminance of a color
 *
 * @param {string} color Any color format
 *
 * @returns {number} The luminance
 */
export function getLuminance(color) {
  if (!color) {
    return;
  }
  const parsed = parseColor(color);

  const ratio = parsed.rgb.map((element) => {
    element /= 255;
    return element <= 0.03928
      ? element / 12.92
      : Math.pow((element + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * ratio[0] + 0.7152 * ratio[1] + 0.0722 * ratio[2];
}

/**
 * Used to calculate the contrast ratio between two colors.
 *
 * @param {string} foreground Any color format
 * @param {string} background Any color format (default white)
 *
 * @returns {number} The contrast ratio
 */
export function contrastRatio(foreground, background = WHITE) {
  if (!foreground || !background) {
    return;
  }
  const foregroundLuminance = getLuminance(foreground);
  const backgroundLuminance = getLuminance(background);

  const contrast =
    foregroundLuminance > backgroundLuminance
      ? (foregroundLuminance + 0.05) / (backgroundLuminance + 0.05)
      : (backgroundLuminance + 0.05) / (foregroundLuminance + 0.05);

  return contrast;
}

/**
 * Used to calculate a lighter version of the given color.
 *
 * @param {string} color Any color format
 * @param {number} percent Lighten by this amount
 *
 * @returns {string} Lighter color in RGB format
 */
export function lightenColor(color, percent) {
  if (!color || !percent) {
    return;
  }
  const parsed = parseColor(color);

  let [red, green, blue] = parsed.rgb;

  red = Math.min(Math.round(red + (255 - red) * percent), 255);
  green = Math.min(Math.round(green + (255 - green) * percent), 255);
  blue = Math.min(Math.round(blue + (255 - blue) * percent), 255);

  return `rgb(${red}, ${green}, ${blue})`;
}

export const coconutTheme = {
  primary: {
    light: '#dde6f6',
    main: blue.A400,
    lightest: '#dde6f6',
  },
  ui: {
    chipBackground: grey[100],
    chipText: grey[700],
    disabled: 'rgba(0, 0, 0, 0.26)',
    draggableBorder: grey[400],
    sectionBorder: grey[300],
  },
  secondary: {
    medium: green[100],
    main: green[500],
  },
  subject: {
    main: grey[700],
    icon: grey[500],
  },
  error: {
    light: red[50],
    main: red[600],
  },
  cancel: red[800],
  warning: {
    light: amber[50],
    main: amber[700],
  },
  info: {
    light: blue[50],
    main: blue.A400,
  },
  success: {
    light: green[50],
    main: green[600],
  },
};

/**
 * Generates the mui theme using the vendor's colors.
 *
 * @param {*} customTheme vendor's custom theme.
 * @param {*} useTheme value for the feature flag shouldHaveMUIUseVendorThemeColor used to wrap vendor colour used across all mui components.
 * @returns mui theme
 */
const getTheme = (customTheme, useTheme = true) => {
  const vendorColor =
    (customTheme && customTheme.primary_colour) || coconutTheme.primary.main;

  const primaryColor =
    contrastRatio(vendorColor) >= MIN_TEXT_CONTRAST_RATIO
      ? vendorColor
      : DEFAULT_MIN_CONTRAST_BLUE;

  const themeColor =
    contrastRatio(vendorColor) >= MIN_COMPONENT_CONTRAST_RATIO
      ? vendorColor
      : DEFAULT_MIN_CONTRAST_BLUE;

  return createMuiTheme({
    borderRadius: {
      sm: '0.125rem',
      md: '0.25rem',
      full: '50%',
    },
    fontFamilies: {
      sansSerif:
        'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    palette: {
      black: BLACK,
      white: WHITE,
      calendar: {
        today: {
          backgroundColor: grey['200'],
        },
      },
      primary: {
        main: useTheme ? primaryColor : coconutTheme.primary.main,
        light: coconutTheme.primary.light,
        lightest: useTheme
          ? lightenColor(primaryColor, 0.915)
          : coconutTheme.primary.lightest,
        contrastText: useTheme
          ? contrastRatio(primaryColor) >= MIN_TEXT_CONTRAST_RATIO
            ? WHITE
            : BLACK
          : WHITE,
      },
      secondary: {
        main: coconutTheme.secondary.main,
      },
      ui: {
        disabled: coconutTheme.ui.disabled,
      },
      subject: {
        main: coconutTheme.subject.main,
        icon: coconutTheme.subject.icon,
      },
      error: {
        light: coconutTheme.error.light,
        main: coconutTheme.error.main,
      },
      section: {
        border: coconutTheme.ui.sectionBorder,
        draggableBorder: coconutTheme.ui.draggableBorder,
      },
      chip: {
        background: coconutTheme.ui.chipBackground,
        text: coconutTheme.ui.chipText,
      },
      warning: {
        light: coconutTheme.warning.light,
        main: coconutTheme.warning.main,
      },
      info: {
        light: coconutTheme.info.light,
        main: coconutTheme.info.main,
      },
      success: {
        light: coconutTheme.success.light,
        main: coconutTheme.success.main,
      },
      themed: {
        main: useTheme ? themeColor : coconutTheme.primary.main,
        contrastText: useTheme
          ? contrastRatio(themeColor) >= MIN_TEXT_CONTRAST_RATIO
            ? WHITE
            : BLACK
          : WHITE,
      },
      defaultThemed: {
        main: vendorColor,
        contrastText: useTheme
          ? contrastRatio(vendorColor) >= MIN_TEXT_CONTRAST_RATIO
            ? WHITE
            : BLACK
          : WHITE,
      },
      neutral: {
        background: grey['100'],
        lightest: grey['200'],
        lighter: grey['300'],
        light: grey['400'],
        main: grey['500'],
        dark: grey['600'],
        darker: grey['700'],
      },
      shadows: {
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.12)',
      },
      input: {
        default: {
          border: '1px solid #ced4da',
        },
        focus: {
          border: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,0.25)',
        },
      },
    },
    textSizes: {
      xxs: '0.625rem', // 10px
      xs: '.75rem', // 12px
      sm: '.875rem', // 14px
      base: '1rem', // 16px
      lg: '1.25rem', // 20px
      xl: '1.5rem', // 24px
    },
    transitions: {
      border: 'border 0.1s',
      boxShadow: 'box-shadow 0.1s',
    },
    typography: {
      useNextVariants: true,
    },
    overrides: {
      Mui: {
        '&$disabled': {
          color: grey['700'],
        },
      },
      // @TODO: This override is temporary
      MuiButton: {
        root: {
          fontWeight: 400,
        },
        containedPrimary: {
          '&:hover': {
            color: coconutTheme.primary.contrastText,
          },
        },
      },
      // @TODO: This override is temporary
      MuiButtonBase: {
        root: {
          textShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
            outline: 0,
          },
        },
      },
      MuiCheckbox: {
        root: {
          '&$checked': {
            color: `${
              useTheme ? primaryColor : coconutTheme.primary.main
            } !important`,
          },
        },
      },
      MuiRadio: {
        root: {
          '&$checked': {
            color: `${
              useTheme ? primaryColor : coconutTheme.primary.main
            } !important`,
            '&$disabled': {
              color: `${coconutTheme.ui.disabled} !important`,
            },
          },
        },
      },
      MuiTypography: {
        caption: {
          color: grey[600],
        },
      },
    },
  });
};

export default getTheme;
