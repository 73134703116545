import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PropTypes from 'prop-types';
import React from 'react';

const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 30 * 60 * 1000,
      staleTime: 1 * 60 * 1000,
      retry: false,
    },
  },
});

const ConditionalDevtools = () => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.MIX_REACT_QUERY_DEBUGGER_ENABLED === 'true'
  ) {
    return <ReactQueryDevtools initialIsOpen={false} />;
  }

  return '';
};

export function ServerStateProvider({
  children,
  queryClient = defaultQueryClient,
}) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ConditionalDevtools />
    </QueryClientProvider>
  );
}

ServerStateProvider.propTypes = {
  children: PropTypes.element,
  // eslint-disable-next-line
  queryClient: PropTypes.any
};
