import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import ForwardChevron from '../../../shared/icons/ForwardChevron';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  button: {
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.default,
    cursor: 'pointer',
    margin: 0,
    marginBottom: '0.75rem',
    padding: 0,
    width: '100%',
    '&:focus': {
      borderColor: theme.palette.secondary[400],
      boxShadow: theme.shadows.card,
      outline: 0,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.secondary['400']}`,
      boxShadow: theme.shadows.hover,
    },
  },
  buttonContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.8rem 1.25rem',
  },
  buttonHeader: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    padding: '0.4rem 1rem',
  },
  icon: {
    marginLeft: '5rem',
    '& svg:first-child': {
      fill: theme.palette.neutral[300],
    },
  },
  iconWithoutMargin: {
    marginLeft: 0,
  },
  itemText: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'left',
  },
  primary: {
    display: 'block',
    maxWidth: '31.25rem',
  },
  textWrapper: {
    marginLeft: '1rem',
  },
  withMargin: {
    marginBottom: '0.25rem',
  },
}));

const Card = ({
  action,
  adornment,
  ariaLabel,
  frontAdornment,
  header,
  icon,
  iconMargin,
  id,
  primary,
  primaryId,
  secondary,
  ...other
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  return (
    <li
      aria-label={
        ariaLabel ||
        intl.formatMessage(
          { id: 'Navigation.aria_label.card' },
          {
            navItem: primaryId
              ? intl.formatMessage({ id: primaryId })
              : primary,
          },
        )
      }
    >
      <div
        className={classes.button}
        data-id={id}
        onClick={action}
        onKeyPress={action}
        role="button"
        tabIndex="0"
        {...other}
      >
        {header ? <div className={classes.buttonHeader}>{header}</div> : null}
        <div className={classes.buttonContent}>
          <div className={classes.itemText}>
            {frontAdornment ? frontAdornment : null}
            <div className={classNames(frontAdornment && classes.textWrapper)}>
              <Typography
                classes={{
                  root: classNames(
                    classes.primary,
                    secondary && classes.withMargin,
                  ),
                }}
                {...(primaryId ? { id: primaryId } : {})}
                variant="title"
              >
                {primary}
              </Typography>
              {secondary ? (
                <Typography variant="caption1">{secondary}</Typography>
              ) : null}
            </div>
          </div>
          {icon ? (
            <div
              className={classNames(
                classes.icon,
                !iconMargin && classes.iconWithoutMargin,
              )}
            >
              <ForwardChevron
                altText={intl.formatMessage({ id: 'Svg.alt_text.continue' })}
                ariaHidden
              />
            </div>
          ) : null}
          {adornment ? adornment : null}
        </div>
      </div>
    </li>
  );
};

Card.propTypes = {
  ariaLabel: PropTypes.string,
  action: PropTypes.func,
  adornment: PropTypes.element,
  frontAdornment: PropTypes.element,
  header: PropTypes.element,
  id: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  icon: PropTypes.bool,
  iconMargin: PropTypes.bool,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  primaryId: PropTypes.string,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Card.defaultProps = {
  action: () => {},
  adornment: null,
  ariaLabel: null,
  frontAdornment: null,
  header: null,
  icon: false,
  iconMargin: true,
  primaryId: '',
  secondary: null,
};

export default Card;
