import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { useHistory } from 'react-router';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import mode from '../../shared/helpers/Mode';
import { RESOURCE_CLIENT_VIEW, SHORTCUTS, MEETING_METHODS } from '../constants';
import Api from '../helpers/Api';
import Open from '../helpers/api/Open';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import { FeatureContext } from './FeatureContext';
import { LocaleContext } from './LocaleContext';
import { SelectionContext } from './SelectionContext';
import { SettingsContext } from './SettingsContext';

/**
 * @type React.Context<{
 *   getCategoryUsers: () => Promise<boolean>,
 *   getSupportedLanguages: (userCategory?: any, preference?: any) => void,
 *   getUsers: () => Promise<boolean>,
 *   loading: boolean,
 *   setState: (_state: any) => void,
 *   supportedLanguages: any[] | null,
 *   supportedLanguagesTranslations?: any | null,
 *   users: { id: string; name: string; role: string; supportedLocales: string[]; spokenLanguages: string[]; profilePhotoUrl?: string, jobTitle?: string }[] | null,
 * }>
 */
const UsersContext = createContext({
  getCategoryUsers: async () => false,
  getSupportedLanguages: () => {},
  getUsers: async () => false,
  loading: false,
  setState: () => {},
  supportedLanguages: null,
  supportedLanguagesTranslations: [],
  users: null,
});

const fetchUsers = ({
  currentUsers = [],
  filterLobbyAvailability,
  locale,
  location,
  locationCategory,
  meetingMethod,
  page = 1,
  service,
  settings,
  userCategory,
}) => {
  const OpenApi = Open.api();

  return OpenApi.locale(locale)
    .users()
    .assigned(true)
    .through(RESOURCE_CLIENT_VIEW)
    .sortBy(!settings?.sort ? 'sort_order' : settings?.sort)
    .at(location && location.id)
    .when(filterLobbyAvailability && location, (api) =>
      api.availableInLobby(location.id),
    )
    .when(service, (api) => api.performing(service.id))
    .when(meetingMethod, (api) => api.supporting(meetingMethod))
    .when(Shortcuts.exists(SHORTCUTS.REGION) && !location, (api) =>
      api.located({ region: Shortcuts.get(SHORTCUTS.REGION) }),
    )
    .when(locationCategory, (api) =>
      api.withinLocationCategory(locationCategory),
    )
    .when(userCategory, (api) => api.withinUserCategory(userCategory.id))
    .when(settings?.invite_only_resources, (api) => api.withInviteOnly())
    .on(page)
    .take(1000)
    .get()
    .then(({ data: { data }, headers: { link } }) => {
      const users = [
        ...currentUsers,
        ...(data?.map((resource) => Resources.formatUser(resource)) || []),
      ];
      const hasNext = link
        ? link.split(', ').find((item) => item.includes('rel="next"'))
        : false;

      if (hasNext && data?.length > 0) {
        return fetchUsers({
          currentUsers: users,
          filterLobbyAvailability,
          locale,
          location,
          locationCategory,
          meetingMethod,
          page: page + 1,
          service,
          settings,
          userCategory,
        });
      }

      return users;
    });
};

const UsersProvider = ({ children }) => {
  const history = useHistory();
  const [locale] = useContext(LocaleContext);
  const features = useContext(FeatureContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);
  const { builderEnabled, showStaffLanguages } = useContext(SettingsContext);
  const [
    {
      location,
      meetingMethod,
      service,
      settings,
      shortcuts,
      userCategory,
      bookingWalkIn,
    },
  ] = useContext(SelectionContext);
  const isMounted = useRef(true);

  const searchParams = new URLSearchParams(history.location.search);
  const shouldFilterByLobbyAvailability =
    shouldUseKioskEnhancements &&
    searchParams.has('use_new_kiosk') &&
    mode.isKiosk() &&
    bookingWalkIn;

  const [
    {
      loading,
      supportedLanguages,
      supportedLanguagesTranslations,
      userLocale,
      userLocation,
      userMethod,
      userService,
      users,
    },
    setState,
  ] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loading: false,
    supportedLanguages: null,
    supportedLanguagesTranslations: window.state?.spoken_language_list,
    userLocale: locale,
    userLocation: null,
    userMethod: null,
    userService: null,
    users: null,
  });

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  const userSelectionsChanged = () => {
    const locationChanged = (location?.id || null) !== userLocation;
    const methodChanged = meetingMethod !== userMethod;
    const serviceChanged = (service?.id || null) !== userService;
    const localeChanged = userLocale !== locale;

    return locationChanged || methodChanged || serviceChanged || localeChanged;
  };

  const hasRequiredSelections = () => {
    // We are temporarily ignoring the destructuring-assignment rule explicitly.
    // There is a bug that was solved in a newer version of this plugin which
    // we will eventually be able to upgrade to once we can move off of
    // the current version of NodeJS in use.
    //
    // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
    //
    // eslint-disable-next-line react/destructuring-assignment
    const methodRequired = features.meetingMethods;
    const locationRequired =
      !methodRequired || meetingMethod === MEETING_METHODS.AT_LOCATION;

    return (
      service &&
      (!methodRequired || meetingMethod) &&
      (!locationRequired || location)
    );
  };

  const getUsers = () => {
    if (
      !builderEnabled &&
      hasRequiredSelections() &&
      ((!users?.length && !loading) || userSelectionsChanged())
    ) {
      setState({
        loading: true,
        userLocale: locale,
        userLocation: location?.id || null,
        userMethod: meetingMethod,
        userService: service?.id || null,
        users: [],
      });

      return fetchUsers({
        filterLobbyAvailability: shouldFilterByLobbyAvailability,
        locale,
        location,
        locationCategory: shortcuts?.location_category?.id || null,
        meetingMethod,
        service,
        settings,
      }).then((fetchedUsers) => {
        if (isMounted.current) {
          setState({
            loading: false,
            users: fetchedUsers,
          });
        }

        return false;
      });
    }

    return Promise.resolve(loading);
  };

  if (userSelectionsChanged() && users) {
    setState({ users: null, loading: false, supportedLanguages: null });
  }

  const getCategoryUsers = () => {
    if (
      !builderEnabled &&
      hasRequiredSelections() &&
      ((!users?.length && !loading) || userSelectionsChanged())
    ) {
      setState({
        loading: true,
        userLocale: locale,
        userLocation: location?.id || null,
        userMethod: meetingMethod,
        userService: service?.id || null,
        users: null,
      });

      return fetchUsers({
        filterLobbyAvailability: shouldFilterByLobbyAvailability,
        locale,
        location,
        locationCategory: shortcuts?.location_category?.id || null,
        meetingMethod,
        service,
        settings,
        userCategory,
      }).then((fetchedUsers) => {
        if (isMounted.current) {
          setState({
            loading: false,
            users: fetchedUsers,
          });
        }

        return false;
      });
    }

    return Promise.resolve(loading);
  };

  const getSupportedLanguages = useCallback(
    (userCategory, preference) => {
      if (!builderEnabled && hasRequiredSelections() && showStaffLanguages) {
        setState({
          loading: true,
          supportedLanguages: null,
        });

        Api.locale(locale)
          .languages()
          .all({
            location,
            locationCategory: Shortcuts.get(SHORTCUTS.LOCATION_CATEGORY),
            method: meetingMethod,
            region:
              Shortcuts.exists(SHORTCUTS.REGION) && !location
                ? Shortcuts.get(SHORTCUTS.REGION)
                : null,
            service,
            settings,
            userCategory,
          })
          .then((languages) => {
            if (userCategory && preference !== null && languages.length === 0) {
              getSupportedLanguages();
              return;
            }

            if (isMounted.current) {
              setState({
                loading: false,
                supportedLanguages: languages,
              });
            }
          });
      }
    },

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location?.id, meetingMethod, service?.id, userCategory?.id],
  );

  return (
    <UsersContext.Provider
      value={{
        getCategoryUsers,
        getSupportedLanguages,
        getUsers,
        loading,
        setState,
        supportedLanguages,
        supportedLanguagesTranslations,
        users,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

UsersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { UsersContext, UsersProvider };
