import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import BackButton from '../../components/BackButton';
import CenterWrap from '../../components/CenterWrap';
import Card from '../../components/desktop/Card';
import AtLocation from '../../components/icons/AtLocation';
import PhoneCall from '../../components/icons/PhoneCall';
import VideoCall from '../../components/icons/VideoCall';
import List from '../../components/List';
import MeetingMethodInfoBanner from '../../components/MeetingMethodInfoBanner';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { MEETING_METHOD_TITLES, MEETING_METHODS, PAGES } from '../../constants';
import { StepAbilityContext } from '../../contexts/StepAbilityContext';
import { ViewModeContext } from '../../contexts/ViewModeContext';
import Step from '../../helpers/Step';
import MeetingMethodsShape from '../../shapes/MeetingMethodsShape';

const icons = {
  [MEETING_METHODS.AT_LOCATION]: <AtLocation ariaHidden />,
  [MEETING_METHODS.PHONE_CALL]: <PhoneCall ariaHidden />,
  [MEETING_METHODS.VIDEO_CALL]: <VideoCall ariaHidden />,
};

const useStyles = createUseStyles((theme) => ({
  adornment: {
    alignItems: 'center',
    background: theme.palette.neutral[100],
    borderRadius: theme.borderRadius.full,
    display: 'flex',
    flexShrink: 0,
    height: '2.5rem',
    justifyContent: 'center',
    width: '2.5rem',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    flex: '1 0 auto',
    maxHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  methods: {
    padding: '1.875rem 1.25rem 1.25rem',
  },
  title: {
    padding: '1.25rem 1.25rem 0',
  },
}));

const MeetingMethods = ({ methods, handleClick, onBack, previousStep }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  const mode = useContext(ViewModeContext);
  const { can } = useContext(StepAbilityContext);

  const header = can.step.backwards ? (
    <header className={classes.header}>
      <BackButton
        previous={onBack}
        text={<FormattedMessage id="Ui.back" />}
        title={Step.getBackString(intl, previousStep)}
      />
    </header>
  ) : null;

  return (
    <CenterWrap data-testid="desktop-meeting-methods" narrow view={mode}>
      <TrackPageView identifier={PAGES.MEETING_METHOD} />
      {header}
      <section className={classes.title}>
        <Typography component="h2" variant="h5">
          <FormattedMessage id="MeetingMethods.header" />
        </Typography>
      </section>
      <section className={classes.methods}>
        <MeetingMethodInfoBanner methods={methods} />
        <List>
          {methods.map((method) => (
            <Card
              action={handleClick}
              data-testid={`meeting-method-card-${method.id}`}
              frontAdornment={
                <div className={classes.adornment}>{icons[method.id]}</div>
              }
              icon
              id={method.id}
              key={method.id}
              primary={
                <FormattedMessage
                  id={MEETING_METHOD_TITLES[method.id].primary}
                />
              }
              primaryId={MEETING_METHOD_TITLES[method.id].primary}
              secondary={
                <FormattedMessage
                  id={MEETING_METHOD_TITLES[method.id].secondary}
                />
              }
            />
          ))}
        </List>
      </section>
    </CenterWrap>
  );
};

MeetingMethods.propTypes = {
  handleClick: PropTypes.func,
  methods: MeetingMethodsShape,
  onBack: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
};

MeetingMethods.defaultProps = {
  handleClick: () => {},
  methods: [],
  previousStep: null,
};

export default MeetingMethods;
