import { Progress } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { FeatureDecisionContext } from '../../../shared/contexts/FeatureDecisionContext';
import List from '../../components/List';
import LocationHeader from '../../components/LocationHeader';
import MobileCard from '../../components/mobile/Card';
import StepCounter from '../../components/StepCounter';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { PAGES, USER_PREFERENCE } from '../../constants';
import { HeaderContext } from '../../contexts/HeaderContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import Step from '../../helpers/Step';
import SupportedLocalePreferenceShape from '../../shapes/SupportedLocalePreferenceShape';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    '& ul': {
      padding: '0 1.25rem',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      gap: '0.25rem',
    },
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
}));

const UserPreference = ({
  canStepBackwards,
  currentStep,
  preferences,
  previous,
  previousStep,
  stepsCount,
  goToNextStep,
  next,
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const [, setHeader] = useContext(HeaderContext);
  const { firstStep } = useContext(SettingsContext);
  const [{ location, skip, bookingWalkIn }, setSelections] =
    useContext(SelectionContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);

  useEffect(() => {
    const canGoToPrevStep = Step.canGoToPrevStep({
      step: 'staff',
      previousStep,
      firstStep,
      skip,
      canStepBackwards,
    });

    setHeader({
      action: canGoToPrevStep ? previous : null,
      title: <FormattedMessage id="Steps.staff" />,
      previousStep,
    });
  }, [canStepBackwards, firstStep, previous, previousStep, setHeader, skip]);

  const setPreference = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    const selection = { userPreference: { id } };
    setSelections(selection);

    if (shouldUseKioskEnhancements && bookingWalkIn) {
      if (id !== USER_PREFERENCE.SPECIFIC) {
        setSelections({ user: null });
      }

      if (id === USER_PREFERENCE.SPECIFIC) {
        return;
      }
    }

    if (goToNextStep) {
      next(selection);
    }
  };

  const header = (
    <header aria-atomic="false" className={classes.header} role="alert">
      {location ? <LocationHeader location={location} /> : null}
      <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
      <Typography component="h1" variant="h5">
        <FormattedMessage id="UserPreference.header" />
      </Typography>
    </header>
  );

  return (
    <section className={classes.root} data-testid="user-preference-mobile">
      <TrackPageView identifier={PAGES.USER_PREFERENCE} />
      {header}
      <List>
        {preferences.length > 0 ? (
          preferences.map((preference) => (
            <MobileCard
              action={setPreference}
              data-testid={preference.testId}
              icon
              id={preference.id}
              key={preference.id}
              primary={intl.formatMessage(
                { id: preference.title },
                preference.values || {},
              )}
              secondary={intl.formatMessage(
                { id: preference.subtitle },
                preference.values || {},
              )}
            />
          ))
        ) : (
          <div className="w-full h-full flex flex-grow justify-center items-center mb-48">
            <Progress size="large" variant="circular" />
          </div>
        )}
      </List>
    </section>
  );
};

UserPreference.propTypes = {
  canStepBackwards: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  preferences: PropTypes.arrayOf(SupportedLocalePreferenceShape).isRequired,
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  stepsCount: PropTypes.number.isRequired,
  goToNextStep: PropTypes.bool,
};

UserPreference.defaultProps = {
  goToNextStep: false,
  previousStep: null,
};

export default UserPreference;
