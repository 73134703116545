import type { FC, ReactNode, Dispatch } from 'react';
import React, { createContext, useReducer } from 'react';

interface HeaderState {
  action?: () => void | null;
  title?: string | JSX.Element | null;
  previousStep?: string | null;
}

type HeaderAction = Partial<HeaderState>;

type HeaderContextType = [HeaderState, Dispatch<HeaderAction>];

const initialState: HeaderState = {
  action: () => {},
  title: document.title,
  previousStep: null,
};

const HeaderContext = createContext<HeaderContextType>([
  initialState,
  () => {},
]);

interface HeaderProviderProps {
  children: ReactNode;
}

const headerReducer = (
  state: HeaderState,
  newState: HeaderAction,
): HeaderState => ({
  ...state,
  ...newState,
});

const HeaderProvider: FC<HeaderProviderProps> = ({ children }) => {
  const [options, setHeader] = useReducer(headerReducer, initialState);

  return (
    <HeaderContext.Provider value={[options, setHeader]}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderProvider };
