import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Detective from '../../../shared/helpers/Detective';
import Checkmark from '../icons/Checkmark';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  bold: {
    fontWeight: `${theme.fontWeights.medium} !important`,
  },
  container: {
    height: '1.25rem',
    position: 'relative',
    width: '1.25rem',
  },
  input: {
    border: `0.125rem solid ${theme.palette.text.secondary}`,
    borderRadius: theme.borderRadius.sm,
    height: '1.25rem',
    margin: 0,
    position: 'relative',
    transition: theme.transitions.border,
    width: '1.25rem',
    '& + svg': {
      display: 'none',
      fill: theme.palette.white,
      height: '1.25rem',
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '1.25rem',
    },
    '&:focus': {
      borderColor: theme.palette.info[100],
      boxShadow: theme.shadows.wrap,
      outline: 0,
    },
    '&:checked': {
      backgroundColor: theme.palette.info[400],
      borderColor: theme.palette.info[400],
      '& + svg': {
        display: 'block',
      },
    },
    '&:active': {
      borderColor: theme.palette.neutral[500],
    },
    '&::-ms-check': {
      border: `0.125rem solid ${theme.palette.text.secondary}`,
      borderRadius: theme.borderRadius.sm,
    },
  },
  inputError: {
    border: `0.125rem solid ${theme.palette.text.secondary}`,
    borderRadius: theme.borderRadius.sm,
    height: '1.25rem',
    margin: 0,
    position: 'relative',
    transition: theme.transitions.border,
    width: '1.25rem',
    '& + svg': {
      display: 'none',
      fill: theme.palette.white,
      height: '1.25rem',
      left: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '1.25rem',
    },
    '&:focus': {
      borderColor: theme.palette.error[600],
      boxShadow: theme.shadows.input,
      outline: 0,
    },
    '&:checked': {
      backgroundColor: theme.palette.error[600],
      borderColor: theme.palette.error[600],
      '& + svg': {
        display: 'block',
      },
    },
    '&::-ms-check': {
      border: `0.125rem solid ${theme.palette.text.secondary}`,
      borderRadius: theme.borderRadius.sm,
    },
  },
  label: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    paddingTop: '0.5rem',
    userSelect: 'none',
  },
  inline: {
    display: 'inline-flex',
    paddingLeft: '0.5rem',
    paddingTop: 0,
  },
  text: {
    marginLeft: '0.5rem',
    fontWeight: theme.fontWeights.normal,
  },
  errorText: {
    marginLeft: '0.5rem',
    fontWeight: theme.fontWeights.normal,
    color: theme.palette.error[600],
  },
}));

const CheckboxInput = ({
  bold,
  defaultChecked,
  error,
  id,
  inline,
  label,
  name,
  onChange,
  value,
}) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <label
      className={classNames(classes.label, inline && classes.inline)}
      htmlFor={id || name}
    >
      <div className={classes.container}>
        <input
          aria-label={label}
          className={error ? classes.inputError : classes.input}
          defaultChecked={defaultChecked}
          id={id || name}
          name={name}
          onChange={onChange}
          type="checkbox"
          value={value}
        />
        {Detective.notInternetExplorer11() && <Checkmark />}
      </div>
      <Typography
        classes={{
          root: classNames(
            error ? classes.errorText : classes.text,
            bold && classes.bold,
          ),
        }}
        variant="label"
      >
        {label}
      </Typography>
    </label>
  );
};

CheckboxInput.propTypes = {
  bold: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  error: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  bold: false,
  defaultChecked: false,
  error: false,
  id: '',
  inline: false,
};

export default CheckboxInput;
