import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Formatter from '../helpers/Formatter';
import ClockOutline from './icons/ClockOutline';
import GroupService from './icons/GroupService';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  content: {
    display: 'contents',
  },
  description: {
    display: 'inline',
    color: theme.palette.black,
    fontSize: theme.textSizes.sm,
    lineHeight: '1.2rem',
    '& > p': {
      lineHeight: '1rem',
      marginTop: '0.25rem',
      verticalAlign: 'top',

      '&:first-child': {
        display: 'inline',
      },
    },
    '& a': {
      ...theme.components.anchors.default,
    },
    '& li': {
      marginLeft: '1rem',
    },
    '& ul': {
      listStyle: 'initial',
    },
    '& ol': {
      listStyle: 'decimal',
    },
    '& em, & i': {
      fontStyle: 'italic',
    },
  },
  dot: {
    color: theme.palette.neutral[400],
    lineHeight: '1rem',
  },
  icon: {
    float: 'left',
    marginLeft: '-0.25rem',
    marginRight: '0.25rem',
    display: 'flex',
    '& svg:first-child': {
      fill: theme.palette.neutral[400],
      height: '1rem',
    },
  },
  text: {
    lineHeight: '1rem',
  },
}));

const ServiceDetails = ({
  description,
  duration,
  group,
  id,
  maximum,
  minimum,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const descriptionRef = useRef();
  const intl = useIntl();
  let time = Formatter.duration(duration, intl);

  if (minimum && maximum) {
    time = `${Formatter.duration(minimum, intl)} - ${Formatter.duration(
      maximum,
      intl,
    )}`;
  }

  return (
    <div className={classes.content}>
      <span className={classes.icon}>
        <ClockOutline />
        <Typography
          classes={{ root: classNames(classes.text) }}
          variant="caption1"
        >
          {time}
        </Typography>
      </span>
      {group ? (
        <span className={classes.icon}>
          <GroupService />
          <Typography
            classes={{ root: classNames(classes.text) }}
            variant="caption1"
          >
            <FormattedMessage id="ServiceHeader.group" />
          </Typography>
        </span>
      ) : null}
      {description !== null ? (
        <div className={classes.content}>
          <span className={classes.dot}> &bull; </span>
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: description }}
            data-testid={`service-description-${id}`}
            ref={descriptionRef}
          />
        </div>
      ) : null}
    </div>
  );
};

ServiceDetails.propTypes = {
  description: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  group: PropTypes.bool,
  id: PropTypes.string.isRequired,
  maximum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minimum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ServiceDetails.defaultProps = {
  description: null,
  group: false,
  maximum: null,
  minimum: null,
};

export default ServiceDetails;
