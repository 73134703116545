import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { WHITE } from '../../backend/helpers/Theme';
import { Clock } from './icons/Clock';

const getBackgroundColor = (theme, animate = false) => {
  if (theme.palette.contrastText === WHITE) {
    return animate ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)';
  }

  return animate ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.15)';
};

const useStyles = createUseStyles((theme) => ({
  subtitle: {
    fontSize: '2.25rem',
    fontWeight: 500,
  },
  tapIcon: {
    backgroundColor: getBackgroundColor(theme),
    animation: '$pulseColor 1.5s infinite ease-in-out',
  },
  pulseScale: {
    animation: '$pulseScale 1.5s infinite cubic-bezier(0.5,2.5,0,1)',
  },
  '@keyframes pulseScale': {
    '0%, 100%': { transform: 'scale(0.92)' },
    '50%': { transform: 'scale(1.2)' },
  },
  '@keyframes pulseColor': {
    '0%, 100%': { backgroundColor: getBackgroundColor(theme) },
    '50%': { backgroundColor: getBackgroundColor(theme, true) },
  },
}));

export const KioskIdle = () => {
  const classes = useStyles();

  return (
    <div className="text-center w-full">
      <div className="max-w-4xl px-12 h-full m-auto flex flex-col items-center gap-8 justify-center">
        <div className="flex flex-col gap-6 items-center justify-center">
          <h2 className="text-5xl font-semibold mb-2">
            <FormattedMessage id="Landing.kiosk.title" />
          </h2>
          <h3 className={classes.subtitle}>
            <FormattedMessage id="Landing.kiosk.subtitle" />
          </h3>
          <div className="flex gap-4 items-center justify-center">
            <Clock className="w-7 h-7 hidden sm:block" />
            <h4 className="text-2xl">
              <FormattedMessage id="Landing.kiosk.hours_temp" />
            </h4>
          </div>
        </div>
        <hr className="bg-current rounded-full h-0.5 w-full my-8 border-0 opacity-25" />
        <div className={`${classes.tapIcon} p-7 rounded-full`}>
          <TapIcon />
        </div>
        <p className="text-xl uppercase">
          <FormattedMessage id="Landing.kiosk.touch" />
        </p>
      </div>
    </div>
  );
};

const TapIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.pulseScale}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4069 39C17.545 39 16.7369 38.819 15.9827 38.4571C15.2285 38.0952 14.5897 37.5825 14.0664 36.919L4 24.3881L4.87734 23.4833C5.49303 22.85 6.23184 22.473 7.0938 22.3524C7.95575 22.2317 8.75613 22.3976 9.49495 22.85L12.912 24.8857V10.0476C12.912 9.53492 13.089 9.10516 13.443 8.75833C13.797 8.41151 14.2357 8.23809 14.759 8.23809C15.2823 8.23809 15.7287 8.41151 16.0981 8.75833C16.4675 9.10516 16.6522 9.53492 16.6522 10.0476V31.4L12.1732 28.6857L16.9755 34.7024C17.1602 34.9135 17.3757 35.0794 17.6219 35.2C17.8682 35.3206 18.1299 35.381 18.4069 35.381H28.6118C29.6277 35.381 30.4974 35.0266 31.2208 34.3179C31.9442 33.6091 32.3059 32.7571 32.3059 31.7619V24.5238C32.3059 24.0111 32.1289 23.5813 31.7749 23.2345C31.4209 22.8877 30.9822 22.7143 30.4589 22.7143H20.3463V19.0952H30.4589C31.9981 19.0952 33.3064 19.623 34.3838 20.6786C35.4613 21.7341 36 23.0159 36 24.5238V31.7619C36 33.7524 35.2766 35.4563 33.8297 36.8738C32.3829 38.2913 30.6436 39 28.6118 39H18.4069ZM6.77056 14.5714C6.37037 13.9079 6.06253 13.1917 5.84704 12.4226C5.63155 11.6536 5.52381 10.8619 5.52381 10.0476C5.52381 7.54444 6.42424 5.41071 8.22511 3.64643C10.026 1.88214 12.2039 1 14.759 1C17.3141 1 19.4921 1.88214 21.2929 3.64643C23.0938 5.41071 23.9942 7.54444 23.9942 10.0476C23.9942 10.8619 23.8865 11.6536 23.671 12.4226C23.4555 13.1917 23.1477 13.9079 22.7475 14.5714L19.5613 12.7619C19.8076 12.3397 19.9923 11.9099 20.1154 11.4726C20.2386 11.0353 20.3001 10.5603 20.3001 10.0476C20.3001 8.53968 19.7614 7.25794 18.684 6.20238C17.6065 5.14682 16.2982 4.61905 14.759 4.61905C13.2198 4.61905 11.9115 5.14682 10.8341 6.20238C9.75661 7.25794 9.21789 8.53968 9.21789 10.0476C9.21789 10.5603 9.27946 11.0353 9.4026 11.4726C9.52573 11.9099 9.71044 12.3397 9.95671 12.7619L6.77056 14.5714Z"
        fill="currentColor"
      />
    </svg>
  );
};
