import clsx from 'clsx';
import type { FC } from 'react';
import React, { useState, useEffect, useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import { KioskIdle } from './KioskIdle';

export const HEADER_HEIGHT = '8rem';
export const FOOTER_HEIGHT = '8rem';
export const IDLE_DURATION = 30000;

const useStyles = createUseStyles((theme: any) => ({
  header: {
    background: theme.palette.primary[400],
    outline: `1px solid ${theme.palette.primary[400]}`, // Fixes sub-pixel rounding issues
    height: HEADER_HEIGHT,
    '& img': { maxHeight: '5rem' },
  },
  footer: {
    background: theme.palette.primary[600],
    height: FOOTER_HEIGHT,
  },
  root: {
    background: theme.palette.primary[600],
    color: theme.palette.contrastText,
  },
  main: {
    background: theme.palette.primary[400],
    height: `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`,
  },
}));

export const EnhancedKioskLanding: FC = () => {
  const classes = useStyles({ theme: useTheme() });
  const mode = useContext(ViewModeContext);
  const isMobile = mode === MOBILE;
  const [isIdleVisible, setIsIdleVisible] = useState(!isMobile);
  const [idleTimer, setIdleTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    const resetTimer = () => {
      setIsIdleVisible(false);
      if (idleTimer) {
        clearTimeout(idleTimer);
      }

      const newTimer = setTimeout(() => {
        setIsIdleVisible(true);
      }, IDLE_DURATION);

      setIdleTimer(newTimer);
    };

    window.addEventListener('mouseup', resetTimer);
    window.addEventListener('touchend', resetTimer);

    return () => {
      window.removeEventListener('mouseup', resetTimer);
      window.removeEventListener('touchend', resetTimer);

      if (idleTimer) {
        clearTimeout(idleTimer);
      }
    };
  }, [idleTimer]);

  return (
    <div
      className={clsx(
        classes.root,
        'flex flex-col justify-center items-center',
      )}
    >
      <Header />
      <main
        className={clsx(
          classes.main,
          'w-full flex overflow-auto rounded-b-3xl',
        )}
      >
        {isIdleVisible ? (
          <KioskIdle />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            Todo: Landing Choices
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

const Header: FC = () => {
  const classes = useStyles({ theme: useTheme() });

  const { logo } = (window as any).state.theme;
  const vendorName = (window as any).state.vendor_name;

  return (
    <div
      className={clsx(classes.header, 'w-full flex items-end justify-center')}
    >
      <div className="bg-white-100 px-8 py-3 rounded-full min-w-40 max-w-160 min-h-20 mx-12 flex items-center justify-center">
        {logo !== null ? (
          <img alt={vendorName} data-testid="logo" src={logo} />
        ) : (
          <h1 className="text-3xl leading-10 font-semibold text-black-90 text-center">
            {vendorName}
          </h1>
        )}
      </div>
    </div>
  );
};

const Footer: FC = () => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <div
      className={clsx(
        classes.footer,
        'w-full flex items-center justify-center',
      )}
    >
      footer
    </div>
  );
};
