import { Alert } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Dates from '../../shared/helpers/Dates';
import Phone from '../../shared/helpers/Phone';
import { MEETING_METHODS, USER_PREFERENCE } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import { MOBILE, TABLET, ViewModeContext } from '../contexts/ViewModeContext';
import Formatter from '../helpers/Formatter';
import Settings from '../helpers/Settings';
import Strings from '../helpers/Strings';
import { useTranslatedLocaleString } from '../hooks/useTranslatedLocaleString';
import AppointmentDetailItem from './AppointmentDetailItem';
import TimezoneLabel from './TimezoneLabel';

const AppointmentSummary = ({ date, finish, reschedule }) => {
  const { showStaffPicker, showStaffAssigned } = useContext(SettingsContext);
  const [
    {
      additionalUsers,
      attendee,
      bookingWalkIn,
      location,
      meetingMethod,
      service,
      user,
      userPreference,
    },
  ] = useContext(SelectionContext);
  const intl = useIntl();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const [timezone] = useContext(TimezoneContext);
  const timezones = useContext(TimezonesContext);
  const cellPhoneIsMasked = attendee?.cellPhone?.includes('*');
  const cellPhone = cellPhoneIsMasked
    ? attendee?.cellPhone
    : Phone.format(attendee?.cellPhone);
  const mode = useContext(ViewModeContext);
  const isMobile = mode === MOBILE;
  const isTablet = mode === TABLET;
  const showStaffName =
    Settings.shouldShowStaffPicker({ showStaffPicker }, location) ||
    Boolean(showStaffAssigned);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);
  const { getTranslatedLocaleString } = useTranslatedLocaleString();

  return (
    <>
      {service ? (
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.service" />}
          primary={service.name}
        />
      ) : null}
      {meetingMethod === MEETING_METHODS.AT_LOCATION ||
      meetingMethod === null ? (
        location?.physical ? (
          <AppointmentDetailItem
            label={<FormattedMessage id="AppointmentDetails.location" />}
            primary={location.name}
            secondary={location.formattedAddress}
          />
        ) : null
      ) : null}
      {meetingMethod === MEETING_METHODS.PHONE_CALL ? (
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.phone_call" />}
          primary={
            reschedule ? (
              <FormattedMessage id="AppointmentDetails.we_will_call_you" />
            ) : (
              <FormattedMessage id="AppointmentDetails.we_will_call" />
            )
          }
          secondary={
            cellPhoneIsMasked ? (
              <span
                aria-label={intl.formatMessage(
                  { id: 'AppointmentDetails.phone_number_ending_in' },
                  {
                    number: cellPhone
                      .substring(cellPhone.length - 3)
                      .split('')
                      .join(' '),
                  },
                )}
                data-personal
              >
                {cellPhone}
              </span>
            ) : (
              <span data-personal>{cellPhone}</span>
            )
          }
        />
      ) : null}
      {meetingMethod === MEETING_METHODS.VIDEO_CALL ? (
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.video_call" />}
          primary={
            reschedule ? (
              <FormattedMessage id="AppointmentDetails.join_the_call" />
            ) : (
              <FormattedMessage id="AppointmentDetails.we_will_send_video_info" />
            )
          }
        />
      ) : null}
      {date ? (
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.date_time" />}
          primary={Dates.toDateMonthYear(date)}
          secondary={Formatter.range(
            date,
            finish(date),
            <TimezoneLabel
              fallback={timezone}
              // We are temporarily ignoring the destructuring-assignment rule explicitly.
              // There is a bug that was solved in a newer version of this plugin which
              // we will eventually be able to upgrade to once we can move off of
              // the current version of NodeJS in use.
              //
              // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
              //
              // eslint-disable-next-line react/destructuring-assignment
              timezone={timezones[timezone]}
            />,
          )}
        />
      ) : null}
      {shouldUseKioskEnhancements &&
      bookingWalkIn &&
      searchParams.has('walk_in') &&
      searchParams.has('use_new_kiosk') &&
      userPreference?.id ? (
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.user_preference" />}
          primary={Strings.uppercaseFirst(
            userPreference?.id === USER_PREFERENCE.SPECIFIC
              ? showStaffName
                ? user?.name
                : intl.formatMessage({ id: 'UserPreference.specific_title' })
              : userPreference?.id === USER_PREFERENCE.RANDOM
              ? intl.formatMessage({ id: 'UserPreference.random_title' })
              : intl.formatMessage(
                  {
                    id: 'SupportedLanguagePreferencePicker.specific_language',
                  },
                  {
                    language: getTranslatedLocaleString(userPreference?.id),
                  },
                ),
          )}
        />
      ) : null}
      {user && showStaffName && !isMobile && !isTablet ? (
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.user" />}
          primary={user.name}
          secondary={user.jobTitle}
        />
      ) : null}
      {user && showStaffName && (isMobile || isTablet) && bookingWalkIn ? (
        <div className="pb-3">
          <Alert severity="info" variant="minimal">
            <Alert.Content>
              <Alert.Description>
                <FormattedMessage
                  id="Steps.no_staff_warning"
                  values={{ name: user.name }}
                />
              </Alert.Description>
            </Alert.Content>
          </Alert>
        </div>
      ) : null}
      {additionalUsers &&
      additionalUsers.length > 0 &&
      additionalUsers.length <= 2 &&
      showStaffName
        ? additionalUsers.map((addUser) => {
            return (
              <AppointmentDetailItem
                key={`additional-user${addUser.id}`}
                narrow
                primary={addUser.name}
                secondary={addUser.jobTitle}
              />
            );
          })
        : null}
      {additionalUsers && additionalUsers.length > 2 && showStaffName ? (
        <>
          <AppointmentDetailItem
            key={`additional-user${additionalUsers[0].id}`}
            narrow
            primary={additionalUsers[0].name}
            secondary={additionalUsers[0].jobTitle}
          />
          <AppointmentDetailItem
            narrow
            secondary={
              <FormattedMessage
                id="AdditionalUsers.plus_more"
                values={{ number: additionalUsers.length - 1 }}
              />
            }
          />
        </>
      ) : null}
    </>
  );
};

AppointmentSummary.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.object,
    PropTypes.string,
  ]),
  finish: PropTypes.func,
  reschedule: PropTypes.bool,
};

AppointmentSummary.defaultProps = {
  date: null,
  finish: () => {},
  reschedule: false,
};

export default AppointmentSummary;
