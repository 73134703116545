import { Progress } from '@coconut-software/ui';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { SERVICE_SEARCH_THRESHOLD } from '../../constants';
import { HeaderContext } from '../../contexts/HeaderContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { useServiceSearch } from '../../contexts/ServiceSearchContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { isSmallScreen } from '../../contexts/ViewModeContext';
import Alert from '../Alert';
import CategoryPicker from '../CategoryPicker';
import SearchableInput from '../forms/SearchableInput';
import List from '../List';
import LocationHeader from '../LocationHeader';
import SinglePageCategory from '../SinglePageCategory';
import StepCounter from '../StepCounter';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  background: {
    background: theme.palette.white,
    padding: '1.25rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  smallScreenPicker: {
    position: 'relative',
    marginLeft: '-0.75rem',
  },
  search: {
    padding: '1.5rem 0 0.5rem',
  },
  smallScreenSearch: {
    padding: '0.5rem 0 0.5rem',
  },
  alert: {
    padding: '1rem 0',
  },
}));

const SinglePageServiceLayout = ({
  categories,
  currentStep,
  loading,
  previous,
  selectService,
  services,
  setPageHeader,
  stepsCount,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const [, setHeader] = useContext(HeaderContext);
  const [category, setCategory] = useState(null);
  const { firstStep } = useContext(SettingsContext);
  const [{ attendee, location }] = useContext(SelectionContext);

  const { filteredServices, handleSearch, query, trackResults } =
    useServiceSearch();

  const handleSelectService = (service, selectedCategory) => {
    trackResults();
    selectService(service, selectedCategory);
  };

  useEffect(() => {
    setPageHeader(category, setCategory);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, previous, setHeader]);

  const selectCategory = (id) => {
    setCategory(categories.find((cat) => cat.id === `${id}`));
  };

  return (
    <div
      aria-atomic="false"
      className={classes.background}
      data-testid="mobile-single-page-layout"
      role="alert"
    >
      {location ? <LocationHeader location={location} /> : null}
      <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
      <div className="h-full flex flex-col">
        <section className="flex items-center">
          <Typography component="h2" variant="h5">
            <FormattedMessage
              id={
                firstStep === 'service' && attendee?.firstName
                  ? 'Service.select_title_prefill'
                  : 'Service.select_title'
              }
              values={{ name: attendee?.firstName, break: <br /> }}
            />
          </Typography>
          {categories.length > 0 && !isSmallScreen() ? (
            <div className="absolute right-0">
              <CategoryPicker
                categories={categories}
                category={category}
                selectCategory={selectCategory}
              />
            </div>
          ) : null}
        </section>
        {loading ? (
          <div className="w-full h-full flex flex-grow justify-center items-center mb-20">
            <Progress size="large" variant="circular" />
          </div>
        ) : null}
        {categories.length > 0 && isSmallScreen() ? (
          <div className={classes.smallScreenPicker}>
            <CategoryPicker
              categories={categories}
              category={category}
              isLeftAligned
              selectCategory={selectCategory}
            />
          </div>
        ) : null}
        {services.length >= SERVICE_SEARCH_THRESHOLD ? (
          <SearchableInput
            className={
              isSmallScreen() ? classes.smallScreenSearch : classes.search
            }
            onChange={handleSearch}
          />
        ) : null}
        {filteredServices.length || !query ? (
          categories.length > 0 ? (
            <List>
              {category ? (
                <SinglePageCategory
                  category={category}
                  inListItem
                  isMobile
                  selectService={handleSelectService}
                  services={filteredServices}
                />
              ) : (
                categories.map((categoryItem) => (
                  <SinglePageCategory
                    category={categoryItem}
                    inListItem
                    isMobile
                    key={categoryItem.id}
                    selectService={handleSelectService}
                    services={filteredServices}
                  />
                ))
              )}
            </List>
          ) : (
            <SinglePageCategory
              category={null}
              isMobile
              selectService={handleSelectService}
              services={filteredServices}
            />
          )
        ) : (
          <div className={classes.alert}>
            <Alert
              message={<FormattedMessage id="Ui.search.no_results_found" />}
              variant="error"
            />
          </div>
        )}
      </div>
    </div>
  );
};

SinglePageServiceLayout.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  currentStep: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  previous: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  setPageHeader: PropTypes.func.isRequired,
  stepsCount: PropTypes.number.isRequired,
};

SinglePageServiceLayout.defaultProps = {
  categories: [],
  services: [],
};

export default SinglePageServiceLayout;
