import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { DEFAULT_SHORTCUT_SETTINGS } from '../../shared/constants';
import { FeatureDecisionContext } from '../../shared/contexts/FeatureDecisionContext';
import Dates from '../../shared/helpers/Dates';
import mode from '../../shared/helpers/Mode';
import {
  GOOGLE_CLIENT_STORAGE_KEY,
  SHORTCUTS,
  STEPS,
  USER_PREFERENCE,
} from '../constants';
import Api from '../helpers/Api';
import Item from '../helpers/Item';
import Languages from '../helpers/Languages';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import Storage from '../helpers/Storage';
import { LocaleContext } from './LocaleContext';
import { SettingsContext } from './SettingsContext';

const SelectionContext = createContext([{}, () => {}]);

export const SELECTION_STORAGE_KEY = 'selection-state';

export const EMPTY_SELECTION = {
  additionalUsers: [],
  appointment: null,
  attendee: null,
  attendees: [],
  date: null,
  googleUser: null,
  location: null,
  locationCategory: null,
  language: Languages.getCurrent() ?? null,
  meetingMethod: null,
  preferredLocation: false,
  region: null,
  search: '',
  service: null,
  serviceCategory: null,
  settings: DEFAULT_SHORTCUT_SETTINGS,
  shortcuts: {},
  showAllLocations: false,
  showRecentLocations: true,
  skip: {
    // will be set on methods step if feature is enabled
    [STEPS.MEETING_METHODS]: true,
    [STEPS.LOCATION]: false,
  },
  uploads: [],
  user: null,
  userCategory: null,
  userPreference: null,
  unvalidatedAttendee: null,
  bookingWalkIn: false,
};

const SelectionProvider = ({ children }) => {
  const [locale] = useContext(LocaleContext);
  const { builderEnabled } = useContext(SettingsContext);
  const { shouldUseKioskEnhancements } = useContext(FeatureDecisionContext);
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const getInitialState = (initial) => {
    const isManage =
      window.location.search.includes('confirm_code') ||
      window.location.search.includes('cid');
    const search = isManage ? '' : window.location.search;
    let prevSelections = Storage.get(SELECTION_STORAGE_KEY);

    const searchQueryChanged =
      prevSelections && Item.get(prevSelections, 'search') !== search;
    const shortcutIdChanged =
      !Item.empty(window.state.shortcuts, true) &&
      window.state.shortcuts?.booking_shortcut_id !==
        prevSelections?.shortcuts?.booking_shortcut_id;

    // do not use the previous selections if shortcuts changed, as they may
    // no longer be valid in combination with the new shortcuts
    if (searchQueryChanged || shortcutIdChanged) {
      Storage.clear(SELECTION_STORAGE_KEY);
      prevSelections = null;
    }

    const state = Shortcuts.overlay(
      Shortcuts.prepare(initial, search),
      prevSelections,
    );

    const googleUser = Storage.getPermanent(GOOGLE_CLIENT_STORAGE_KEY);

    if (googleUser && !mode.isEmbedded()) {
      state.googleUser = googleUser;
      const [firstName, ...lastName] = googleUser.name.split(' ');
      if (!state.attendee) {
        state.attendee = {
          email: googleUser.email,
          firstName,
          lastName: lastName.join(' '),
          cellPhone: googleUser.cellPhone,
        };
      }
    }

    if (state) {
      const timezone = Storage.get(SHORTCUTS.TIMEZONE);

      state.date = state.date ? Dates.parse(state.date, timezone) : null;

      if (Item.empty(state.shortcuts, true)) {
        state.shortcuts = window.state.shortcuts;
      }
    }

    state.language = locale;

    const shouldSetWalkInSelections =
      urlParams.has('walk_in') &&
      urlParams.has('use_new_kiosk') &&
      mode.isBookingThroughKioskQR() &&
      shouldUseKioskEnhancements;

    if (shouldSetWalkInSelections) {
      if (urlParams.has('preferred_lang')) {
        state.userPreference = { id: urlParams.get('preferred_lang') };
      } else if (
        !urlParams.has('preferred_lang') &&
        !urlParams.has('preferred_staff_id')
      ) {
        state.userPreference = { id: USER_PREFERENCE.RANDOM };
      }
    }

    if (!Storage.get(SELECTION_STORAGE_KEY)) {
      // save so shortcuts don't get cleared if page is reloaded before making a selection
      const encoded = {
        ...state,
        date: state.date ? state.date.format('iso') : null,
        shortcuts: state.shortcuts,
      };

      Storage.set(SELECTION_STORAGE_KEY, encoded);
    } else if (Item.has(state, 'shortcuts')) {
      window.state.shortcuts = Item.get(state, 'shortcuts');
    }

    // TODO check for new query params to decide if we're booking a walk-in earlier in the flow

    return state;
  };

  const [selections, setSelections] = useReducer(
    (state, newState) => {
      const value = {
        ...state,
        ...newState,
      };

      const encoded = {
        ...value,
        date: value.date ? value.date.format('iso') : null,
      };

      Storage.set(SELECTION_STORAGE_KEY, encoded);

      return value;
    },
    EMPTY_SELECTION,
    getInitialState,
  );

  const oldLocale = useRef(locale);

  useEffect(() => {
    const onMessage = ({ data }) => {
      const isCorrect =
        Item.has(data, 'first_name') ||
        Item.has(data, 'last_name') ||
        Item.has(data, 'email') ||
        Item.has(data, 'cell_phone') ||
        Item.has(data, 'answers') ||
        Item.has(data, 'external_id');

      if (isCorrect) {
        setSelections({
          attendee: {
            cellPhone: data.cell_phone || null,
            email: data.email || null,
            externalId: data.external_id || null,
            firstName: data.first_name || null,
            lastName: data.last_name || null,
            questions: (data.answers || []).reduce((object, answer) => {
              object[answer.qId] = answer.value;

              if (Item.isArray(answer.value)) {
                object[answer.qId] = answer.value.reduce((obj, option) => {
                  obj[option] = true;

                  return obj;
                }, {});
              }

              return object;
            }, {}),
          },
        });
      }
    };

    window.addEventListener('message', onMessage);
    window.parent.postMessage('ready:client-details', '*');
    window.setClientDetails = function (
      firstName,
      lastName,
      emailAddress,
      cellNumber,
      answers,
      externalId,
    ) {
      onMessage({
        data: {
          first_name: firstName,
          last_name: lastName,
          email: emailAddress,
          cell_phone: cellNumber,
          answers,
          external_id: externalId,
        },
      });
    };

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    const { additionalUsers, location, service, settings, user } = selections;
    if (oldLocale.current !== locale) {
      oldLocale.current = locale;
      if (location && location.id) {
        Api.locale(locale)
          .locations()
          .find({ location: location.id, settings })
          .then(({ data }) => {
            const updatedLocation = Resources.formatLocation(data[0]);
            location.name = updatedLocation.name;
            if (mode.isKiosk) {
              location.external_id = updatedLocation.external_id;
            }
            setSelections({ location });
          });
      }

      if (service && service.id) {
        Api.locale(locale)
          .services()
          .find({ service: service.id, settings })
          .then(({ data }) => {
            const updatedService = Resources.formatService(data);
            service.name = updatedService.name;
            service.description = updatedService.description;
            service.instructions = updatedService.instructions;
            setSelections({ service });
          });
      }

      if (user && user.id) {
        Api.locale(locale)
          .users()
          .find({ user: user.id, settings })
          .then(({ data }) => {
            const updatedUser = Resources.formatUser(data);
            user.jobTitle = updatedUser.jobTitle;
            user.meeting_info = updatedUser.meeting_info;
            setSelections({ user });
          });
      }

      if (additionalUsers.length > 0 && additionalUsers.length <= 2) {
        Api.locale(locale)
          .users()
          .get(additionalUsers.map((addUser) => addUser.id))
          .then(({ data }) => {
            data.forEach((updated) => {
              const formatted = Resources.formatUser(updated);
              const index = additionalUsers.findIndex(
                (old) => old.id === updated.id,
              );
              additionalUsers[index].jobTitle = formatted.jobTitle;
              additionalUsers[index].meeting_info = formatted.meeting_info;
            });

            setSelections({ additionalUsers: [...additionalUsers] });
          });
      } else if (additionalUsers.length > 2) {
        Api.locale(locale)
          .users()
          .find({ user: additionalUsers[0].id, settings })
          .then(({ data }) => {
            const updatedUser = Resources.formatUser(data);
            additionalUsers[0].jobTitle = updatedUser.jobTitle;
            additionalUsers[0].meeting_info = updatedUser.meeting_info;

            setSelections({ additionalUsers: [...additionalUsers] });
          });
      }
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    if (builderEnabled) {
      setSelections({ userCategory: null });
    }
  }, [builderEnabled]);

  return (
    <SelectionContext.Provider value={[selections, setSelections]}>
      {children}
    </SelectionContext.Provider>
  );
};

SelectionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SelectionContext, SelectionProvider };
