import { PAGE_TITLES, STEPS } from '../constants';

export default {
  /**
   * @param {string} pointer
   *
   * @returns {string|null}
   */
  getStep(pointer) {
    switch (pointer) {
      case '/data/attributes/service_id':
        return STEPS.SERVICE;
      case '/data/attributes/location_id':
        return STEPS.LOCATION;
      case '/data/attributes/start':
        return STEPS.TIMES;
      default:
        return null;
    }
  },

  /**
   * @param {object} intl
   * @param {string} previousStep
   * @returns {string}
   */
  getBackString(intl, previousStep) {
    return previousStep
      ? intl.formatMessage(
          { id: 'Ui.back_to_previous' },
          { previous: intl.formatMessage({ id: PAGE_TITLES[previousStep] }) },
        )
      : intl.formatMessage({ id: 'Ui.back' });
  },

  /**
   * @param {object} intl
   * @param {string} nextStep
   * @returns {string}
   */
  getNextString(intl, nextStep) {
    return nextStep
      ? intl.formatMessage(
          { id: 'Ui.next_to_previous' },
          { next: intl.formatMessage({ id: PAGE_TITLES[nextStep] }) },
        )
      : intl.formatMessage({ id: 'Ui.next' });
  },

  canGoToPrevStep({
    step,
    previousStep,
    firstStep = '',
    skip,
    canStepBackwards,
  }) {
    switch (step) {
      case STEPS.LOCATION:
        return (
          (canStepBackwards || !skip[STEPS.MEETING_METHODS]) &&
          firstStep === STEPS.SERVICE
        );
      case 'staff':
        return canStepBackwards;
      case STEPS.TIMES:
        if (previousStep === 'staff') {
          return true;
        }

        return canStepBackwards;
      default:
        return false;
    }
  },
};
