import classNames from 'classnames';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import HomeButton from '../../shared/components/HomeButton';
import { useFeatureDecisions } from '../../shared/contexts/FeatureDecisionContext';
import Detective from '../../shared/helpers/Detective';
import mode from '../../shared/helpers/Mode';
import { HEADER_HEIGHT } from '../constants';
import { HeaderContext } from '../contexts/HeaderContext';
import { SettingsContext } from '../contexts/SettingsContext';
import {
  MOBILE,
  ViewModeContext,
  isSmallScreen,
} from '../contexts/ViewModeContext';
import Step from '../helpers/Step';
import BackArrow from './icons/BackArrow';
import ForwardArrow from './icons/ForwardArrow';
import LanguagePicker from './LanguagePicker';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.white,
    borderTop: `0.375rem solid ${theme.palette.primary[400]}`,
    boxShadow: theme.shadows.default,
    display: 'flex',
    flexShrink: 0,
    height: HEADER_HEIGHT.DESKTOP,
    justifyContent: 'center',
    position: Detective.notInternetExplorer11() ? 'sticky' : 'relative',
    top: 0,
    zIndex: 950,
  },
  action: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    '&:first-child': {
      marginLeft: '1rem',
    },
    '&:last-child': {
      opacity: 0,
      pointerEvents: 'none',
      visibility: 'hidden',
    },
    '& svg': {
      fill: theme.palette.neutral[400],
    },
    '&:focus': {
      '& svg': {
        fill: theme.palette.black,
      },
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  customAction: {
    '&:first-child': {
      display: 'flex',
    },
    '&:last-child': {
      display: 'none',
      marginLeft: 0,
    },
  },
  customContainer: {
    justifyContent: 'space-between',
  },
  customLanguage: {
    position: 'inherit !important',
    marginLeft: 'auto',
  },
  customTitle: {
    height: 'auto',
    maxHeight: '2.25rem',
    maxWidth: '70%',
    paddingLeft: '1.25rem',
  },
  customRoot: {
    justifyContent: 'space-between',
  },
  desktopLogo: {
    padding: '0.25rem 0.5rem',
  },
  vendorName: {
    width: '100%',
    textAlign: 'center',
    marginLeft: ({ isSmallScreen }) => (isSmallScreen ? '2rem' : '6rem'),
  },
  homepage: {
    textDecoration: 'none',
    marginLeft: 'auto',
  },
  homepageImage: {
    '&:focus': {
      '& img': {
        borderRadius: '0.25rem',
        outline: `2px solid ${theme.palette.secondary['400']}`,
        outlineOffset: '-2px',
      },
    },
  },
  homepageText: {
    '&:focus': {
      '&:first-child': {
        borderRadius: '0.25rem',
        outline: `2px solid ${theme.palette.secondary['400']}`,
        outlineOffset: '-2px',
      },
    },
  },
  home: {
    position: 'absolute',
    left: '1rem',
    top: '55%',
    transform: 'translateY(-50%)',
  },
  logo: {
    maxWidth: '40vw',
    maxHeight: '3.125rem',
  },
  langPickerMargin: {
    marginLeft: ({ isSmallScreen }) => (isSmallScreen ? '2rem' : '6rem'),
  },
  logoWrapper: {
    marginLeft: 'auto',
  },
  mobileLogo: {
    padding: '0.5rem 1rem',
  },
  subtitle: {
    transform: 'translateX(30%)',
  },
  stepTitle: {
    width: '100%',
    minWidth: '140px',
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  marginAuto: {
    marginLeft: 'auto',
  },
}));

const Header = () => {
  const classes = useStyles({
    theme: useTheme(),
    isSmallScreen: isSmallScreen(),
  });
  const intl = useIntl();

  const viewMode = useContext(ViewModeContext);
  const [{ action, title, previousStep }] = useContext(HeaderContext);
  const { availableLanguages, homepage } = useContext(SettingsContext);
  const { shouldUseKioskUXEnhancements } = useFeatureDecisions();

  const isTouchKiosk = mode.isKiosk() && Detective.touchscreen();
  const onLanding =
    window.location.href.includes('/how') ||
    window.location.href.includes('/where');

  if (mode.isEmbedded()) {
    return null;
  }

  if (shouldUseKioskUXEnhancements && onLanding && isTouchKiosk) {
    return null;
  }

  const { logo } = window.state.theme;
  const showPicker = availableLanguages.length > 1;
  const customStyle = viewMode === MOBILE && action && showPicker;
  const vendorName = window.state.vendor_name;

  const logoImage = (
    <div className={classes.logoWrapper}>
      <h1>
        <img
          alt={vendorName}
          className={classNames(
            classes.desktopLogo,
            classes.logo,
            showPicker ? classes.langPickerMargin : '',
          )}
          data-testid="logo"
          src={logo}
        />
      </h1>
    </div>
  );

  const header =
    logo !== null ? (
      <>
        {homepage && !mode.isKiosk() ? (
          <a
            className={classNames(classes.homepage, classes.homepageImage)}
            href={homepage}
            rel="noreferrer"
            target="_blank"
            title={intl.formatMessage(
              { id: 'WhatNow.homepage' },
              { vendor: vendorName },
            )}
          >
            {logoImage}
          </a>
        ) : (
          logoImage
        )}
        <div
          className={classNames(
            customStyle && classes.customLanguage,
            classes.marginAuto,
          )}
        >
          <LanguagePicker />
        </div>
      </>
    ) : (
      <>
        {homepage && !mode.isKiosk() ? (
          <a
            className={classNames(classes.vendorName, classes.homepageText)}
            href={homepage}
            rel="noreferrer"
            target="_blank"
            title={intl.formatMessage(
              { id: 'WhatNow.homepage' },
              { vendor: vendorName },
            )}
          >
            <Typography
              classes={{ root: classNames(customStyle && classes.customTitle) }}
              component="h1"
              variant="h6"
            >
              {vendorName}
            </Typography>
          </a>
        ) : (
          <Typography
            classes={{
              root: classNames(
                customStyle && classes.customTitle,
                classes.vendorName,
              ),
            }}
            component="h1"
            variant="h6"
          >
            {vendorName}
          </Typography>
        )}
        {showPicker ? (
          <div className={customStyle ? classes.customLanguage : undefined}>
            <LanguagePicker />
          </div>
        ) : null}
      </>
    );

  const showButton =
    (viewMode !== MOBILE || !action) && !onLanding && mode.isKiosk();
  const buttonClass = classNames(classes.action, classes.home);
  const homeButton = showButton ? <HomeButton className={buttonClass} /> : null;

  if (homeButton) {
    return (
      <header className={classes.root}>
        {homeButton}
        {header}
      </header>
    );
  }

  const backMessage = Step.getBackString(intl, previousStep);

  const iFrameHeader = (
    <>
      <Typography
        classes={{ root: classes.customTitle }}
        component="h2"
        variant="h6"
      >
        {title}
      </Typography>
      {showPicker ? (
        <div className={classes.customLanguage}>
          <LanguagePicker />
        </div>
      ) : null}
    </>
  );

  return (
    <header
      className={classNames(classes.root, customStyle && classes.customRoot)}
    >
      {action && viewMode === MOBILE ? (
        <div
          className={classNames(
            classes.container,
            customStyle && classes.customContainer,
          )}
        >
          <button
            aria-label={backMessage}
            className={classNames(
              classes.action,
              customStyle && classes.customAction,
            )}
            data-testid="back"
            onClick={action}
            title={backMessage}
            type="button"
          >
            <BackArrow />
          </button>
          <Typography className={classes.stepTitle} component="h2" variant="h6">
            {title}
          </Typography>
          {showPicker ? (
            <LanguagePicker />
          ) : (
            <button
              aria-hidden
              className={classNames(
                classes.action,
                customStyle && classes.customAction,
              )}
              data-testid="forward"
              type="button"
            >
              <ForwardArrow />
            </button>
          )}
        </div>
      ) : Detective.inIFrame() ? (
        iFrameHeader
      ) : (
        header
      )}
    </header>
  );
};

export default Header;
