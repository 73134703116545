import {
  en,
  es,
  fr,
  ko,
  pl,
  pt,
  ru,
  zh,
} from '@coconut-software/ui/dist/locales';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import synonymReplacement from '../../shared/helpers/SynonymReplacement';
import useToggleTranslations from '../../shared/hooks/useToggleTranslations';
import { updateCommonHeaders } from '../helpers/api/Client';
import messages from '../locales';

const LocaleContext = createContext([
  window.initialState?.user?.locale || 'en',
  () => {},
]);

const useLocale = () => useContext(LocaleContext);

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(
    window.initialState?.user?.locale || 'en',
  );
  useToggleTranslations(locale, setLocale);

  useEffect(() => {
    updateCommonHeaders({ 'Accept-Language': locale });
  }, [locale]);

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      <IntlProvider
        locale={locale}
        messages={synonymReplacement(
          {
            ...{ en, es, fr, ko, pl, pt, ru, zh }[locale],
            ...messages[locale],
          },
          window.initialState?.vendor?.synonyms,
        )}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { LocaleProvider, useLocale };
